import { colors, sizes } from "../../../styles/clickable";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    color?: keyof typeof colors;
    loading?: boolean;
    size?: keyof typeof sizes;
}

export default function Button({
    children,
    className,
    color = "white",
    type = "button",
    size = "md",
    loading,
    ...rest
}: ButtonProps) {
    return (
        <button
            className={`inline-flex items-center border shadow-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-25 transition ease-in-out duration-150 ${className} ${colors[color]} ${sizes[size]}`}
            type={type}
            {...rest}
        >
            {loading ? (
                <svg
                    className="animate-spin h-5 w-5 mx-auto"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                    ></circle>
                    <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                </svg>
            ) : (
                children
            )}
        </button>
    );
}
